<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<style>

body {
    margin: 0;
}
</style>

