<template>
    <div class="search-container">
        <div class="search-box">
            <div class="soico"></div>
            <form @submit.prevent="search" class="search-form">
                <div class="search-input-wrapper">
                    <input v-model="query.wd" type="text" placeholder="请输入电影名称..." class="search-input"/>
                    <span @click="search" class="search-icon"></span>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const query = ref({ wd: '' });
const router = useRouter();

function search() {
    router.push({ name: 'results', query: { wd: query.value.wd } });
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.search-box {
    text-align: center;
    font-family: 'Roboto', sans-serif;
}

.search-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #ffccff, #ccffff, #cc99ff);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.soico {
    background: url('https://qiniu.htoo.vip/img/VRlogo.svg') no-repeat;
    width: 150px;
    height: 140px;
    margin: 0 auto 1rem auto;
    background-size: cover;
    background-position: 0 -10px;
}

.search-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.search-input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.search-input {
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    font-size: 1.2rem;
    border: 1px solid #ccc;
    border-radius: 25px; /* 圆角边框 */
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0); /* 半透明背景 */
}

.search-input:focus {
    border-color: #0088cc;
    outline: none;
}

.search-icon {
    position: absolute;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    background: url('https://img.icons8.com/ios-filled/50/0088cc/search.png') no-repeat center/contain;
    cursor: pointer;
}
</style>
