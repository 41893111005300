// main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import axios from 'axios'

createApp(App).use(router).use(ElementPlus).mount('#app')

router.beforeEach(async (to, from, next) => {
    let title = to.meta.title || 'TV在线搜'

    // 动态设置标题
    if (to.name === 'results' && to.query.wd) {
        title = `${to.query.wd}_${title}`
    } else if (to.name === 'detail' && to.params.id) {
        try {
            // 假设通过 API 获取相关数据
            const response = await axios.get(`/api/inc/api_mac10.php`, {
                params: { ac: 'detail', ids: to.params.id }
            })

            if (response.data.code === 1) {
                const detail = response.data.list[0] || {}
                const vodName = detail.vod_name || '未知名称'
                const currentEpisode = detail.vod_play_url.split('#')[0].split('$')[0] || '未知集数'
                title = `${vodName}_${currentEpisode}_${title}`
            }
        } catch (error) {
            console.error('获取详情数据时出错：', error)
        }
    }

    document.title = title
    next()
})
